import { shareFilesBy } from "@ntprsrf-frontend/service/src/constants/share-files-type.constant";
import { eguaranteeTypes } from "@ntprsrf-frontend/internet/src/constants/APIEnums";
import { RouteRecordRaw } from "vue-router";

const governmentServiceRoute: RouteRecordRaw[] = [
  {
    path: "government-services",
    name: "GOVERNMENT_SERVICES",
    redirect: () => {
      return {
        name: "PERMIT_RETURN",
      };
    },
    children: [
      {
        path: "list",
        name: "GOVERNMENT_SERVICES_LIST",
        component: () => import("@/views/GovernmentServices.vue"),
        meta: {
          isInformationalService: true,
          ignoresAuth: true,
          label: "Government Services",
        }
      },
      {
        path: "favourites-list",
        name: "FAVOURITE_GOVERNMENT_SERVICES_LIST",
        component: () => import("@/views/GovernmentServices.vue"),
        meta: {
          isInformationalService: true,
          label: "Favourite Services",
        }
      },
      {
        path: "permit-clearance-enquiry",
        name: "PERMIT_CLEARANCE_ENQUIRY",
        meta: {
          transactionalServiceId: 'ntp-5023',
          ignoresAuth: true,
          label: "Permit Clearance Enquiry",
          eguaranteeTypesCanAccess: [
            eguaranteeTypes.NORMAL.VALUE,
            eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
            eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
          ],
        },
        component: () =>
          import("@/views/GovernmentServices/PermitClearance.vue"),
      },
      {
        path: "container-track-and-trace",
        name: "CONTAINER_TRACK_AND_TRACE",
        meta: {
          transactionalServiceId: 'ntp-5024',
          label: "Container Track and Trace",
          eguaranteeTypesCanAccess: [
            eguaranteeTypes.NORMAL.VALUE,
            eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
            eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
          ],
        },
        component: () =>
          import("@/views/GovernmentServices/ContainerTrackAndTrace.vue"),
      },
      {
        meta: {
          label: "Permit Return",
          eguaranteeTypesCanAccess: [
            eguaranteeTypes.NORMAL.VALUE,
            eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
            eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
          ],
        },
        name: "PERMIT_RETURN",
        path: "permit-return",
        redirect: () => {
          return {
            name: "SEARCH_UI",
          };
        },
        component: () => import("@/views/GovernmentServices/PermitReturn.vue"),
        children: [
          {
            path: "search-ui",
            name: "SEARCH_UI",
            component: () => import("@/views/GovernmentServices/SearchUI.vue"),
            meta: {
              label: "Search",
              transactionalServiceId: 'ntp-5021',
              eguaranteeTypesCanAccess: [
                eguaranteeTypes.NORMAL.VALUE,
                eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
                eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
              ],
            },
          },
          {
            path: "my-permit",
            name: "MY_PERMITS",
            component: () => import("@/views/GovernmentServices/MyPermits.vue"),
            meta: {
              label: "My Permits",
              eguaranteeTypesCanAccess: [
                eguaranteeTypes.NORMAL.VALUE,
                eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              ],
            },
          },
          {
            path: "data-consent",
            name: "DATA_CONSENT",
            meta: {
              label: "Data Consent",
              eguaranteeTypesCanAccess: [
                eguaranteeTypes.NORMAL.VALUE,
                eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              ],
            },
            component: () =>
              import("@/views/GovernmentServices/DataConsent.vue"),
          },
          {
            path: "received-permits",
            name: "RECEIVED_PERMITS",
            component: () => import("@/views/GovernmentServices/ReceivedPermit.vue"),
            meta: {
              label: "Received Permit",
              eguaranteeTypesCanAccess: [
                eguaranteeTypes.NORMAL.VALUE,
                eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              ],
              isReceivedPermit: true
            },
          },
        ],
      },
      {
        path: "e-guarantee",
        name: "E_GUARANTEE@GOV",
        redirect(to) {
          return {
            name: "E_GUARANTEE@GOV_LIST",
            params: {
              eSharePath: shareFilesBy,
            },
          };
        },
        meta: {
          label: "eGuarantee@Gov",
          eguaranteeTypesCanAccess: [
            eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
            eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
          ],
        },
        children: [
          {
            path: ":eSharePath",
            name: "E_GUARANTEE@GOV_LIST",
            meta: {
              breadcrumbParam: "eSharePath",
              eguaranteeTypesCanAccess: [
                eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
                eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
              ],
            },
            component: () =>
              import("@/views/GovernmentServices/EGuarantee@Gov.vue"),
          },
          {
            path: ":companyUEN",
            meta: {
              breadcrumbParam: "companyUEN",
              isCompanyStore: true,
            },
            redirect(to) {
              return {
                name: "E_GUARANTEE@GOV",
              };
            },
            name: "E_GUARANTEE_TO_COMPANIES",
            children: [
              {
                path: "add-file",
                component: () =>
                  import("@/views/GovernmentServices/AddEGuaranteeFiles.vue"),
                name: "E_GUARANTEE_ADD_FILES",
                meta: {
                  label: "Share eGuarantee files",
                  eguaranteeTypesCanAccess: [
                    eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
                    eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
                  ],
                },
              },
            ],
          },
          {
            path: "add-beneficiary-file",
            component: () =>
              import("@/views/GovernmentServices/AddEGuaranteeFiles.vue"),
            name: "E_GUARANTEE_BENEFICIARY_FILE",
            meta: {
              label: "Share eGuarantee files",
              eguaranteeTypesCanAccess: [
                eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              ],
            },
          },
        ],
      },
      {
        path: "e-services-and-forms",
        name: "E_SERVICES_AND_FORMS",
        meta: {
          label: "Government Services",
          eguaranteeTypesCanAccess: [
            eguaranteeTypes.NORMAL.VALUE,
            eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
          ],
        },
        component: () =>
          import("@/views/GovernmentServices/EServicesAndForms.vue"),
      },
      {
        path: "hand-carried-exports-scheme",
        name: "HAND_CARRIED_EXPORTS_SCHEME",
        meta: {
          transactionalServiceId: 'ntp-5025',
          label: "Hand-Carried Exports Scheme (HCES) Digital Service",
          eguaranteeTypesCanAccess: [
            eguaranteeTypes.NORMAL.VALUE,
            eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
            eguaranteeTypes.GOVERNMENT_AGENCY.VALUE,
          ],
        },
        component: () =>
          import("@/views/GovernmentServices/HandCarriedExportsScheme.vue"),
      },
    ],
  },
];

export default governmentServiceRoute;
